import React from 'react';
import {useStaticQuery, graphql} from "gatsby";

import Layout from "../components/layouts/Layout";
import Meetup from "../components/pages/schedule/Meetup";
import Seo from "../components/main/SEO";

import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import Col from "../components/styled-components/wrappers/Col";
import LinkButton from "../components/styled-components/button/LinkButton";
import Divider from "../components/styled-components/divider/Divider";
import H2 from "../components/styled-components/typography/H2";

const Schedule = () => {

    const data = useStaticQuery(graphql`
    {
      allStrapiBlackowlMeetups(filter: {isActive: {eq: true}}) {
        activeMeetup: nodes {
          id
          title
          date
        }
      }
      allStrapiBlackowlEvents(
            filter: {blackowl_meetup: {isActive: {eq: true}}, slug: {ne: ""}}
            sort: {order: ASC, fields: date}
          ) {
            activeEvents: nodes {
              date
              slug
              title
              speaker {
                avatar {
                  url
                }
                slug
                name
              }
            }
          }
      allMeetups: allStrapiBlackowlMeetups(
        filter: {slug: {ne: "hamarosan"}, isActive: {ne: true}}
        sort: {order: DESC, fields: date}
      ) {
        notActiveMeetups: nodes {
          title
          date
          slug
          id
        }
      }
      notActiveEvents: allStrapiBlackowlEvents(
            filter: {blackowl_meetup: {isActive: {eq: false}}}
            sort: {order: ASC, fields: date}
          ) {
            notActiveEvent: nodes {
              date
              slug
              title
              blackowl_meetup {
                slug
                id
              }
              speaker {
                avatar {
                  url
                }
                slug
                name
              }
            }
          }
    }
  `)

    const {allStrapiBlackowlMeetups: {activeMeetup}} = data;
    const {allStrapiBlackowlEvents: {activeEvents}} = data;
    const {allMeetups: {notActiveMeetups}} = data;
    const {notActiveEvents: {notActiveEvent}} = data;

    return (
        <Layout title={"Program"}>
            <Seo title={"Balasys Blackowl - Program"} description={"A Balasys Blackowl program oldala."} keywords={"balasys, blackowl, program"} url={"/schedule"}/>
            <SectionLayoutGrid>
                <Meetup
                    key={activeMeetup[0].id}
                    title={activeMeetup[0].title}
                    date={activeMeetup[0].date}
                    events={activeEvents}
                />
                {activeEvents.length!==0 &&
                <Col full center marginBottom="medium">
                    <LinkButton to={"/#register"}>Regisztráció</LinkButton>
                </Col>
                }
                <Col full marginBottom="medium">
                    <Divider full/>
                </Col>
                <Col full marginBottom="small">
                    <H2 medium>Korábbi meetupok</H2>
                    <Divider fullOrange/>
                </Col>
                {notActiveMeetups.map((item) => (
                        <Meetup
                            key={item.id}
                            title={item.title}
                            date={item.date}
                            events={notActiveEvent.filter(f => f.blackowl_meetup.slug===item.slug)}
                        />
                    )
                )}
            </SectionLayoutGrid>
        </Layout>
    );
};

export default Schedule;