import React from 'react'
import moment from "moment"
import {Link} from "gatsby"
import styled from "styled-components"
import 'moment/locale/hu';

import Col from "../../styled-components/wrappers/Col"
import H2 from "../../styled-components/typography/H2"
import P from "../../styled-components/typography/P"
import Divider from "../../styled-components/divider/Divider"
import {Avatar} from "../../styled-components/cards/EventCard/EventCardComponents";

const TitleWrapper = styled.div`
  display: flex;
  max-width: 770px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-self: center;
  margin-bottom: 50px;
`

const EventWrapper = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  border-top: 1px solid #e0dee9;
  max-width: 1000px;
  width: 100%;
  justify-self: center;
  padding: 20px;
  &:hover{
    background-color: #f7f6fa;
    transition: background-color 100ms ease, border 100ms ease, color 200ms ease;
  }
  
  &:last-child{
    border-bottom: 1px solid #e0dee9;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 14px;
    grid-row-gap: 5px;
    justify-items: center;
  }
`

const Meetup = ({date, title, events, isIndex}) => {

    return (
        <Col full center grid marginBottom="small">
            <TitleWrapper>
                <P italic>{moment(date).locale("hu").format("LL")}</P>
                <H2 medium>{!isIndex ? title : "Program"}</H2>
                <Divider center/>
            </TitleWrapper>
            {events && events.map((item, index) => (
                <EventWrapper to={"/events/" + item.slug} key={index}>
                    <P scheduleTime center>{moment(item.date).format("HH:mm")}</P>
                    <P eventAvatar center>{item.title}</P>
                    <Avatar large src={item.speaker.avatar.url} alt={item.speaker.slug}/>
                </EventWrapper>
            ))}
        </Col>
    );
};

export default Meetup;